import React, { Component } from 'react';
import Tabulator from 'shared/table/Tabulator';
import api from '@api';
import Loading from 'shared/loading';
import DeliveryActions from './actions';
import { actionButton } from 'shared/icons';
import { CustomModal } from 'shared/modal';
import EditBill from '../bills/edit';
import { checkPermission } from '../../permissions';
import { invoicePDFlink } from '../../config';
import './tableStyle.scss';
import { toast } from 'react-toastify';
import ErrorOnLoading from 'shared/errorOnLoad';
import NoData from 'shared/noDataNotification';
import MyCustomTable from '../../latraps_table';
import { convertColumn } from 'latraps_table/cols';

const actionsButtonFormatter = () => actionButton;

const formatDate = dateStr => {
	if (!dateStr) {
		return '';
	}
	return `${dateStr.substring(6, 8)}.${dateStr.substring(4, 6)}.${dateStr.substring(0, 4)}.`;
};

const dateFormatter = (data, tableMode) => {
	const needMappostIcon = tableMode == 'archive' || tableMode == 'aizvestais' ? false : true;

	return (
		<>
			{formatDate(data.uzkrauts)}
			<br />
			Pasūt. #{data.orderId}
			<br />
			{needMappostIcon &&
				data.mappostTaskId &&
				data.mappostTaskId !== '' &&
				'Mappost ID: ' + data.mappostTaskId}
		</>
	);
};

const ordererFormatter = ({ orderClient: { name, address, regNr } }) => {
	return (
		<span className='preWrap' style={{ fontWeight: 'bold' }}>
			{name}
			<br />
			{address}{' '}
			<i
				className='fas fa-share'
				onClick={() => window.open(`#/client/${regNr}`, '_self')}></i>
		</span>
	);
};

const routeFormatter = data => {
	return (
		<>
			no:{' '}
			<b>
				<span className='storageClicking'>{data.storage.title}</span>
			</b>
			<br />
			uz:{' '}
			<b>
				<span className='destinationClicking'>
					{data.payerWithDeliveryAddressData.address}
				</span>
			</b>
			{data.crm && data.crm !== '' && (
				<span>
					<br />- PVZ: <span style={{ color: '#ff0000' }}>{data.crm}</span>
				</span>
			)}
		</>
	); //${notes}
};

const mappost_statuss_number_to_text = n => {
	/*
        StatusID values for default status group:
        0    Nav sākts (start status)
        1    Sākts
        2    Pārtraukts
        3    Atsākts
        4    Pabeigts
        5    Problēma
    */
	if (n == 0) {
		return <span style={{ color: '#ff0000' }}>Nav sākts</span>;
	}
	if (n == 1) {
		return (
			<>
				<i className='fas fa-clock fa-1x'></i>
				<span style={{ color: '#000' }}> Sākts...</span>
			</>
		);
	}
	if (n == 2) {
		return (
			<>
				<i className='fas fa-exclamation-triangle fa-1x' style={{ color: 'orange' }}></i>
				<span style={{ color: 'blue', fontWeight: 'bold' }}> Pārtraukts</span>
			</>
		);
	}
	if (n == 3) {
		return (
			<>
				<i className='fas fa-clock fa-1x'></i>
				<span style={{ color: '#000' }}> Atsākts...</span>
			</>
		);
	}
	if (n == 4) {
		return (
			<>
				<i className='fas fa-check-circle fa-1x' style={{ color: 'green' }}></i>
				<span style={{ color: 'green' }}> Pabeigts</span>
			</>
		);
	}
	if (n == 5) {
		return <span style={{ color: '#ff0000', fontWeight: 'bold' }}> Problēma</span>;
	}
};
const carrierFormatter = data => {
	if (data.carrierDriverId == 65) {
		return <span style={{ color: '#ff0000' }}>pārvadātājs nav norādīts</span>;
	}

	const carrierData = data.carrierData;

	const driver =
		carrierData.driver && carrierData.driver !== '' ? (
			<>
				<br />
				{carrierData.driver}
			</>
		) : (
			''
		);
	const phone =
		carrierData.phone && carrierData.phone !== '' ? (
			<>
				<br />
				t. {carrierData.phone}
			</>
		) : (
			''
		);
	let piekabe =
		carrierData.piekabe && carrierData.piekabe !== '' ? (
			<>
				<br />
				<b>{carrierData.piekabe}</b>
			</>
		) : (
			''
		);
	const vilcejs =
		carrierData.vilcejs && carrierData.vilcejs !== '' ? (
			<>
				<br />
				<b>{carrierData.vilcejs}</b>
			</>
		) : (
			''
		);

	piekabe = piekabe == '' ? vilcejs : piekabe; //nezinu, vai vilcejs vai piekabe ir vajadzīgs

	let mappostText;

	if (data.mappostTaskId) {
		if (data.mappostSubtaskStatus == 5 && data.mappostComment !== '') {
			mappostText = (
				<span style={{ color: 'red' }}>
					<span
						title='Apskatīt problēmu'
						onClick={() => alert(`Šoferis ziņo: ${data.mappostComment}`)}>
						<span
							style={{ color: 'red' }}
							className='glyphicon glyphicon-exclamation-sign'
							aria-hidden='true'></span>{' '}
						Problēma
					</span>
				</span>
			);
		} else {
			mappostText = mappost_statuss_number_to_text(data.mappostSubtaskStatus);
		}
	}

	return (
		<span className='preWrap'>
			<b>{carrierData.title}</b>
			{driver}
			{phone}
			{piekabe}
			<br />
			{mappostText}
		</span>
	);
};

const toggleClickingSupport = (currSate, cb) => {
	const storages = document.getElementsByClassName('storageClicking');
	const destinations = document.getElementsByClassName('destinationClicking');

	console.log(storages, currSate, storages.length);

	for (var i = 0, j = storages.length; i < j; i++) {
		if (currSate == 'enabled') {
			storages[i].removeEventListener('click', cb, true);
		} else {
			storages[i].addEventListener('click', cb, true);
		}
	}

	for (let i = 0; i < destinations.length; i++) {
		if (currSate == 'enabled') {
			destinations[i].removeEventListener('click', cb, true);
		} else {
			destinations[i].addEventListener('click', cb, true);
		}
	}
};

const materialFormatter = data => {
	const notes =
		data.deliveryNotes && data.deliveryNotes !== '' ? (
			<>
				<br />- <span style={{ color: '#ff0000' }}>{data.deliveryNotes}</span>
			</>
		) : (
			''
		);
	const deliveryIncl =
		data.isDeliveryIncludedInPrise && data.isDeliveryIncludedInPrise === 'on' ? (
			<>
				<br />- piegāde iekļauta produkta cenā
			</>
		) : (
			''
		);

	return (
		<span className='preWrap'>
			<b>{data.materialTitle}</b>
			{notes}
			<br />- {data.department}
			{deliveryIncl}
			<br />- {data.worker}
		</span>
	);
};

const amountFormatter = (data, type) => {
	if (type === 'planned') {
		return (
			<>
				<b>{data.plannedAmount}</b> <b>{data.measurementUnit}</b>
			</>
		);
	}
	if (type === 'delivered') {
		return (
			<>
				<b>{data.deliveredAmount}</b> <b>{data.measurementUnit}</b>
			</>
		);
	}
};

const priceFormatter = data => {
	return (
		<>
			<b>{data.cena}</b> &euro; / {data.cenaPar}
			{data.cena != data.pasutitajaCena && (
				<>
					<br />
					<b>{data.pasutitajaCena}</b> &euro; / {data.pasutCenaPar}
				</>
			)}
		</>
	);
};

const expensesFormatter = data => {
	const exp1 = parseFloat(data.expenses).toFixed(2);
	const exp2 = parseFloat(data.expensesWithOrderPrise).toFixed(2);

	return (
		<>
			{exp1} &euro;
			{exp1 != exp2 && (
				<>
					<br />
					{exp2} &euro;
				</>
			)}
		</>
	);
};

const expensesDeliveryFormatter = data => {
	const exp1 = parseFloat(data.deliveryExpenses).toFixed(2);
	const exp2 = parseFloat(data.deliveryExpensesWithOrderPrise).toFixed(2);

	return (
		<>
			{exp1} &euro;
			{exp1 != exp2 && (
				<>
					<br />
					{exp2} &euro;
				</>
			)}
		</>
	);
};

const fileFormatter = data => {
	if (data.file != '<img alt=pic src=../images/transp.png border=0>') {
		if (data.file.startsWith('http')) {
			return (
				<a href={data.file} target='_blank'>
					<i
						className='fas fa-file-invoice fa-2x'
						style={{ verticalAlign: 'middle' }}></i>
				</a>
			);
		}

		const finalFile = data.file.replace(
			'<img src=images/Image-JPEG-icon.png border=0 alt=icon>',
			'<i class="fas fa-file-invoice fa-2x" style="vertical-align:middle;"></i>'
		);

		return <div dangerouslySetInnerHTML={{ __html: finalFile }}></div>;
	}

	return '';
};

const paymentStatusFormatter = ({ deliveryPaymentStatus, isDeliveryIncludedInPrise, isOrdererPriceApproved }) => {
	if (isDeliveryIncludedInPrise && isDeliveryIncludedInPrise == 'on') {
		return (
			<span className='preWrap' style={{ color: 'green' }}>
				piegāde iekļauta produkta cenā
			</span>
		);
	}
	if (Object.keys(deliveryPaymentStatus).length == 0) {
		return '';
	}
	const { status, message, billData } = deliveryPaymentStatus;

	let billLink;
	if (typeof billData !== 'undefined') {
		const { nr, originalAuthor, date, finanseYear, pvz } = billData;
		const formattedDate =
			typeof date == 'undefined'
				? ''
				: `${date.substring(6, 8)}.${date.substring(4, 6)}.${date.substring(0, 4)}.`;
		billLink = (
			<span onClick={() => window.open(`${invoicePDFlink}${pvz}`, '_blank')}>
				<i
					className='fas fa-file-pdf fa-2x'
					style={{ color: 'red', verticalAlign: 'middle' }}></i>
				<br />
				LOG <b>{nr}</b>/{finanseYear}
				<br />
				{formattedDate}
				<br />
				{originalAuthor}
				<br />
			</span>
		);
	}
	let statusText;
	if (status == 'warning') {
		statusText = (
			<span style={{ color: 'red' }}>
				<span className='glyphicon glyphicon-exclamation-sign' aria-hidden='true'></span>{' '}
				{message}
			</span>
		);
	} else if (status == 'ready' || status == 'bill') {
		statusText = <span style={{ color: 'green' }}>{message}</span>;
	}

	const warning2 = !isOrdererPriceApproved || isOrdererPriceApproved == 0 ? <><br /><span style={{color:'red'}}><span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> pasūtītāja cena nav apstiprināta!</span></> : '';

	return (
		<span className='preWrap'>
			{billLink}
			{statusText}
			{warning2}
		</span>
	);
};

const truckIcon = color => {
	return (
		<i
			className='fas fa-truck-moving fa-2x'
			style={{ color, textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000' }}></i>
	);
};
const statusFormatter = ({ image, pgStatus }) => {
	let finalImage;

	if (image == 'truck-1.png') {
		finalImage = truckIcon('red');
	} else if (image == 'truck-2.png') {
		finalImage = truckIcon('#FFFF33');
	} else if (image == 'truck-3.png') {
		finalImage = truckIcon('green');
	} else if (image == 'pdf.png') {
		finalImage = <i className='fas fa-file-pdf fa-2x'></i>;
	} else if (image == 'lock.png') {
		finalImage = (
			<i
				className='fas fa-lock fa-2x'
				style={{
					color: 'yellow',
					textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
				}}></i>
		);
	} else {
		finalImage = <img src={`images/${image}`} />;
	}

	return (
		<>
			{finalImage}
			<br />
			{pgStatus}
		</>
	);
};

const handleOpenOrderer = ({orderClient: { regNr }}) => {
	window.open(`#/client/${regNr}`, '_self');
};

class Deliveries extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			showModal: '',
			currTask: null,
			error: false,
			billEditData: {},
			source: 'parent',  //parent komponente vai API fetch
		};
	}

	handleOpenActions = ({ taskId, deliveryId }) => {
		this.handleOpenModal({ taskId, deliveryId });
	}

	setupData() {
		const { data } = this.props;

		if (data) {
			this.setState({ loading: false, error: false, data, source: 'parent' });
			return;
		}

		this.setState({ loading: true, error: false });

		api.deliveries.overview().then(response => {
			this.setState({ loading: false, error: false, data: response, source: 'fetch' });
		});
	}

	handleOpenInvoice = data => {
		const {
			deliveryPaymentStatus: { status, message, billData },
			dataForInvoice: { client, order },
			pgStatus
		} = data;

		if (message == 'piegāde gatava iekļaušanai rēķinā' && pgStatus != 'aizvests') {
			toast.warn('Šī piegāde nav atzīmēta kā aizvesta', { autoClose: 7500 });
			return false;
		}

		if (
			status != 'ready' ||
			message != 'piegāde gatava iekļaušanai rēķinā' ||
			pgStatus != 'aizvests'
		) {
			return false;
		}

		if (!checkPermission('edit', 'bills') || !checkPermission('add', 'bills')) {
			return false;
		}

		this.setState({ showModal: 'bills', billEditData: { payer: client, order, type: 'OK' } });
	};

	componentDidMount() {
		this.setupData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data !== prevProps.data || this.state.source !== prevState.source) {
			this.setupData();
		}
	}

	columns = [
		{
			name: 'date',
			Header: () => (
				<i
					title='Datums, kurā šoferis uzsāka piegādi'
					className='fas fa-calendar-alt'
					style={{ color: '#333334' }}></i>
			),
			accessor: 'uzkrauts',
			Cell: ({ row }) => (
				<div style={{ textAlign: 'center' }}>
					{dateFormatter(row.original, this.props.mode)}
					{this.props.isMassEditButtonsActive && (
						<input
							type="checkbox"
							checked={this.props.isSelected.includes(row.original.deliveryId)}
							onChange={(e) => this.props.onCheckboxChange(row.original.deliveryId, e.target.checked)}/>
					)}
				</div>
			)
		},
		{
			name: 'actions',
			Header: () => (
				<i
					title='Atvērt darbību sarakstu'
					className='fas fa-edit'
					style={{ color: '#333334' }}></i>
			),
			headerVertical: 'flip',
			width: 50,
			align: 'center',
			accessor: 'taskId',
			clickHandler: this.handleOpenActions,
			Cell: ({ row }) => (
				<div>
					<i
						className='fas fa-ellipsis-v'
						style={{ fontSize: '1.6em', textAlign: 'center', color: 'green' }}></i>
				</div>
			)
		},
		{
			name: 'paymentStatus',
			width: 100,
			Header: () => (
				<i
					title='Apmaksa'
					className='fas fa-file-invoice-dollar'
					style={{ color: '#333334' }}></i>
			),
			accessor: 'deliveryPaymentStatus',
			Cell: ({ row }) => (
				<div onClick={() => this.handleOpenInvoice(row.original)}>
					{paymentStatusFormatter(row.original)}
				</div>
			)
		},
		{
			name: 'orderer',
			Header: () => (
				<i title='Pasūtītājs' className='fas fa-building' style={{ color: '#333334' }}></i>
			),
			width: 150,
			accessor: 'orderClient',
			Cell: ({ row }) => (
				<div className='shrinkLines' onClick={() => handleOpenOrderer(row.original)}>
					{ordererFormatter(row.original)}
				</div>
			)
		},
		{
			name: 'route',
			Header: () => (
				<i title='Maršruts' className='fas fa-road' style={{ color: '#333334' }}></i>
			),
			width: 150,
			accessor: 'route',
			Cell: ({ row }) => <div className='shrinkLines'>{routeFormatter(row.original)}</div>
		},
		{
			name: 'carrier',
			Header: () => (
				<i
					title='Pārvadātājs'
					className='fas fa-truck-moving'
					style={{ color: '#333334' }}></i>
			),
			width: 150,
			accessor: 'carrier',
			Cell: ({ row }) => <div className='shrinkLines'>{carrierFormatter(row.original)}</div>
		},
		{
			name: 'material',
			Header: () => (
				<i
					title='Materiāls, piezīmes'
					className='fas fa-clipboard'
					style={{ color: '#333334' }}></i>
			),
			width: 150,
			accessor: 'storage',
			Cell: ({ row }) => <div className='shrinkLines'>{materialFormatter(row.original)}</div>
		},
		{
			name: 'plannedAmount',
			Header: () => (
				<i
					title='Kopējais apjoms atdots uz piegādi šoferim šī pasūtījuma ietvaros'
					className='fas fa-truck'
					style={{ color: '#ccff99' }}></i>
			),
			width: 75,
			accessor: 'plannedAmount',
			Cell: ({ row }) => <div>{amountFormatter(row.original, 'planned')}</div>
		},
		{
			name: 'deliveredAmount',
			Header: () => (
				<i
					title='Konkrētā šofera aizvestais apjoms'
					className='fas fa-shipping-fast'
					style={{ color: '#333334' }}></i>
			),
			width: 75,
			accessor: 'deliveredAmount',
			Cell: ({ row }) => <div>{amountFormatter(row.original, 'delivered')}</div>
		},
		{
			name: 'madeKm',
			Header: () => (
				<i
					title='Brauciena izpildes laikā nobrauktie kilometri'
					className='fas fa-route'
					style={{ color: '#333334' }}></i>
			),
			width: 55,
			accessor: 'routeLength'
		},
		{
			name: 'hours',
			Header: () => (
				<i
					title='Brauciena izpildes laikā nostrādātās stundas'
					className='fas fa-user-clock'
					style={{ color: '#333334' }}></i>
			),
			width: 45,
			accessor: 'travelTime'
		},
		{
			name: 'price',
			Header: () => (
				<i title='Cena' className='fas fa-euro-sign' style={{ color: '#333334' }}></i>
			),
			width: 75,
			accessor: 'price',
			Cell: ({ row }) => <div>{priceFormatter(row.original)}</div>
		},
		{
			name: 'expense',
			Header: () => (
				<i title='Izmaksas' className='fas fa-euro-sign' style={{ color: '#333334' }}></i>
			),
			width: 75,
			accessor: 'expenses',
			Cell: ({ row }) => <div>{expensesFormatter(row.original)}</div>
		},
		{
			name: 'expenseDelivery',
			Header: () => (
				<i
					title='Piegādes izmaksas'
					className='fas fa-euro-sign'
					style={{ color: '#333334' }}></i>
			),
			width: 75,
			accessor: 'expenseDelivery',
			Cell: ({ row }) => <div>{expensesDeliveryFormatter(row.original)}</div>
		},
		{
			name: 'file',
			Header: () => (
				<i
					title='Piegādes apstiprinājuma dokuments'
					className='fas fa-file-image'
					style={{ color: '#333334' }}></i>
			),
			width: 75,
			accessor: 'file',
			Cell: ({ row }) => <div>{fileFormatter(row.original)}</div>
		},
		{
			name: 'status',
			Header: () => (
				<i title='Statuss' className='fas fa-signal' style={{ color: '#333334' }}></i>
			),
			width: 75,
			accessor: 'status',
			Cell: ({ row }) => <div>{statusFormatter(row.original)}</div>
		},
		{
			name: 'invoiceReceived',
			Header: () => (
				<i title='Saņemts ienākošais rēķins' className='fas fa-file-invoice-dollar' style={{ color: '#333334' }}></i>
			),
			width: 75,
			accessor: 'invoiceReceived',
			Cell: ({ row }) => <div style={{ textAlign: 'center' }}>{row.original.invoiceReceived == 1 ? 'Jā' : 'Nē'}</div>
		}
	];

	handleCloseModal = () => {
		this.setState({ showModal: '' });

		this.props.reload_data(false);
	};

	handleOpenModal = ({ taskId, deliveryId }) => {
		this.setState({ showModal: 'actions', currTask: taskId, currDelivery: deliveryId });
	};

	generateColumns(data) {
		const { exclude, mode } = this.props;

		let toExclude = [];

		if (typeof mode !== 'undefined' && mode == 'aizvestais') {
			let canExclude = true;

			for (let i = 0; i < data.length; i++) {
				if (data[i].pgStatus != 'aizvests') {
					canExclude = false;
					break;
				}
			}

			toExclude = canExclude ? ['status'] : [];
		}

		//const toExclude = typeof exclude == 'undefined' ? ['route','orderer'] : exclude;
		const finalColumns = this.columns.filter(item => !toExclude.includes(item.name));

		return finalColumns;
	}

	render() {
		const {
			data,
			error,
			loading,
			showModal,
			currDelivery,
			currTask,
			billEditData
		} = this.state;

		const columns = this.generateColumns(data);

		if (error) {
			return <ErrorOnLoading />;
		}

		if (loading) {
			return <Loading />;
		}

		if (data.length < 1) {
			return <NoData />;
		}

		return (
			<>
				<div className='tableWrapper'>
					<MyCustomTable
						columns={columns}
						tooltipsHeader={true}
						orders={data}
						options={data.length < 25 ? { pagination: false } : { paginationSize: 25 }}
					/>
				</div>

				{showModal && (
					<DeliveryActions
						showModal={showModal == 'actions'}
						deliveryId={currDelivery}
						taskId={currTask}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
				<CustomModal
					title='Labošana'
					showModal={showModal == 'bills'}
					handleCloseModal={this.handleCloseModal}
					content={
						<EditBill data={billEditData} handleCloseModal={this.handleCloseModal} />
					}
				/>
				{/* "showModal &&"  ir vajadzīgs, lai neveidojas lieki AJAX pieprasījumi. Protams, ir jāatrod pareizais risinājums, lai nerenderējas kompoenente */}
			</>
		);
	}
}

export default Deliveries;
