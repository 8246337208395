import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import api from '@api';
import OrderEditModal from './newModal';
import Loading from 'shared/loading';
import Deliveries from '../deliveries/table';
import Header from '../deliveries/header';
import NoData from 'shared/noDataNotification';

class UzdevumiModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: '',
			orderNr: '',
			data: [],
			filteredData: [],
			clientKey: 0,
			clientsList: [],
			loading: true,
			historyOpen: false,
			showEditModal: false,
			statusChangeOpen: false,
			orderStatus: null
		};
	}

	componentDidMount() {
		const { orderId, orderNr, planningOrderId } = this.props;

		this.setState({ orderId, orderNr, planningOrderId }, () => this.setup());
	}

	handleCloseEditModal = () => {
		this.props.handleCloseEditModal();
	}

	componentDidUpdate(prevProps) {
		if (this.props.orderId !== prevProps.orderId || this.props.planningOrderId !== prevProps.planningOrderId) {
			const { orderId, orderNr, planningOrderId } = this.props;

			this.setState({ orderId, orderNr, planningOrderId }, () => this.setup());
		}
	}

	setup = (loader = true) => {
		const { orderId, planningOrderId } = this.state;

		if (orderId === null && planningOrderId === null) {
			//šis izpildās, kad aizver modal logu
			this.setState({ data: [], loading: false });
			return;
		}

		if (loader) {
			this.setState({ loading: true, error: false });
		}

		if (planningOrderId) {
			api.planning.order_tasks(planningOrderId).then(response => {
				this.setState({ loading: false, error: false, data: response });
			}).then(() => {
				this.getClientList();
			});
			return;
		}

		api.deliveries.deliveryOrder(orderId).then(response => {
			this.setState({ loading: false, error: false, data: response });
		}).then(() => {
			this.getClientList();
		});
	}

	getClientList = () => {
		const { data } = this.state;

		let clients = [];
		data.forEach((delivery, ind) => {
			const clientTitle = delivery.taskDeliveries.deliveries[0]?.carrierData?.title;

			if (clientTitle && !clients.some(client => client.name === clientTitle)) {
				clients.push({ id: ind + 1, name: clientTitle });
			}
		});

		const finalClientList = [
			{ id: 0, name: 'VISI PĀRVADĀTĀJI' },
			...clients.sort((a, b) => a.name.localeCompare(b.name))
		];

		this.setState({ clientsList: finalClientList, clientKey: 0, filteredData: [] });
	}

	filterDeliveries = (client) => {
		const { data } = this.state;
		const filteredData = client.id === 0 ? data : data.filter(
				delivery => delivery.taskDeliveries.deliveries[0].carrierData?.title === client.name
			);

		this.setState({ filteredData, clientKey: client.id });
	}

	clientOption = (client) => {
		return (
			<button
				type='button'
				key={client.id}
				className={this.state.clientKey === client.id ? 'btn-custom active' : 'btn-custom'}
				onClick={() => this.filterDeliveries(client)}
				>
				{client.name}
			</button>
		);
	};

	closeModalWithoutReload = () => {
		this.setState({
			showEditModal: false
		});
	}

	render() {
		const { data, filteredData, clientsList, loading, orderNr, planningOrderId } = this.state;
		const dataToShow = filteredData.length > 0 ? filteredData : data;

		const title = planningOrderId ?
			`Plānošanas pasūtījuma #${planningOrderId} reģistrētie uzdevumi` :
			`Pasūtījuma #${orderNr} reģistrētie uzdevumi`;

		const renderContent = () => {
			if (loading) return <Loading />;
			if (dataToShow.length === 0) return <NoData />;

			return (
				<>
					{clientsList.length > 2 && (<>
						<div className='clientBtnLayout'>
							{clientsList.map((client, ind) =>
								this.clientOption(client)
							)}
						</div>
						<br />
					</>)}

					{dataToShow.map((delivery, ind) => (
						<div key={ind}>
							<Header data={delivery} mode='normal' resetData={this.setup} />
							<br />
							<Deliveries data={delivery.taskDeliveries.deliveries} reload_data={this.setup} />
						</div>
					))}
				</>
			)
		};

		return (
			<div>
				<CustomModal
					modalClass='fullWidthModal'
					title={title}
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={
						<div>
							{renderContent()}
						</div>
					}
				/>
				<OrderEditModal
					showModal={this.state.showEditModal}
					handleCloseModal={this.handleCloseEditModal}
					closeModalWithoutReload={this.closeModalWithoutReload}
				/>
			</div>
		);
	}
}

export default UzdevumiModal;
