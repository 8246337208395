import React, { Component } from 'react';
import { CustomModal } from 'shared/modal';
import api from '@api';
import OrderEditModal from './newModal';
import Loading from 'shared/loading';
import Deliveries from '../deliveries/table';
import NoData from 'shared/noDataNotification';
import MassDeliveryEdit from 'sections/deliveries/MassDeliveryEdit';
import { connect } from 'react-redux';

class VedumiModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: '',
			orderNr: '',
			data: {},
			filteredData: [],
			clientKey: 0,
			clientsList: [],
			driversList: [],
			driverKey: 0,
			loading: true,
			historyOpen: false,
			showEditModal: false,
			statusChangeOpen: false,
			orderStatus: null,
			showMassDeliveryEditModal: false,
			isSelected: [],
			isMassEditButtonsActive: false,
		};
	}

	componentDidMount() {
		const { orderId, orderNr, billId, planningOrderId } = this.props;

		this.setState({ orderId, orderNr, billId, planningOrderId }, () => this.setup());
	}

	componentDidUpdate(prevProps) {
		if (this.props.orderId !== prevProps.orderId ||
			this.props.billId !== prevProps.billId ||
			this.props.planningOrderId !== prevProps.planningOrderId)
			{
			const { orderId, orderNr, billId, planningOrderId } = this.props;

			this.setState({ orderId, orderNr, billId, planningOrderId }, () => this.setup());
		}
	}

	getByOrder = (id) => {
		this.setState({ loading: true, error: false });

		api.deliveries.orderDeliveries(id).then(response => {
			this.setState({ loading: false, error: false, data: response });
		}).then(() => {
			this.getClientList();
			this.getDriversList();
		});
	}

	getByBill = id => {
		this.setState({ loading: true, error: false });

		api.bills.deliveries(id).then(response => {
			this.setState({ loading: false, error: false, data: response });
		}).then(() => {
			this.getClientList();
			this.getDriversList();
		});
	}

	handleCloseEditModal = () => {
		this.props.handleCloseEditModal();
	}

	setup() {
		const { orderId, billId, planningOrderId } = this.props;

		if (orderId === null && billId === null && planningOrderId === null) {
			//šis izpildās, kad aizver modal logu
			this.setState({ data: {} });
			return;
		}

		if (planningOrderId) {
			api.planning.order_deliveries(planningOrderId).then(response => {
				this.setState({ loading: false, error: false, data: response });
			}).then(() => {
				this.getClientList();
				this.getDriversList();
			});
			return;
		}

		if (orderId) {
			this.getByOrder(orderId);
		}

		if (billId) {
			this.getByBill(billId);
		}
	}

	closeModalWithoutReload = () => {
		this.setState({
			showEditModal: false
		});
	}

	handleOpenMassDeliveryEditModal = () => {
		this.setState({ showMassDeliveryEditModal: true });
	};

	handleCloseMassDeliveryEditModal = () => {
		this.setState({ showMassDeliveryEditModal: false });
	};

	handleCheckboxChange = (deliveryId, isSelected) => {
		this.setState(prevState => {
			const newIsSelected = [...prevState.isSelected];
			if (isSelected) {
				if (!newIsSelected.includes(deliveryId)) {
					newIsSelected.push(deliveryId);
				}
			} else {
				const index = newIsSelected.indexOf(deliveryId);
				if (index > -1) {
					newIsSelected.splice(index, 1);
				}
			}
			return { isSelected: newIsSelected };
		});
	};

	selectAllDeliveries = () => {
		this.setState(prevState => {
			const deliveryIds = prevState.data.deliveries.flatMap(delivery => delivery.deliveryId);
			const isSelected = prevState.isSelected.length ? [] : deliveryIds;
			return { isSelected };
		});
	};

	activateMassEdit = () => {
		this.setState(prevState => ({ isMassEditButtonsActive: !prevState.isMassEditButtonsActive }));
	};

	getClientList = () => {
		const data = this.state.data.deliveries;

		let clients = [];
		data.forEach((delivery, ind) => {
			const clientTitle = delivery.carrierData.title;

			if (clientTitle && !clients.some(client => client.name === clientTitle)) {
				clients.push({ id: ind + 1, name: clientTitle });
			}
		});

		const finalClientList = [
			{ id: 0, name: 'VISI PĀRVADĀTĀJI' },
			...clients.sort((a, b) => a.name.localeCompare(b.name)),
		];

		this.setState({
			clientsList: finalClientList, clientKey: 0,
			filteredData: []
		});
	}

	getDriversList = () => {
		const { data, filteredData } = this.state;
		const dataToProcess = filteredData.length > 0 ? filteredData : data.deliveries;

		let drivers = [];
		dataToProcess.forEach((delivery, ind) => {
			const driverName = delivery.carrierData.driver;
			const driverCar = delivery.carrierData.vilcejs;

			if (driverName && driverCar && !drivers.some(driver => driver.name === driverName && driver.car === driverCar)) {
				drivers.push({ id: ind + 1, name: driverName, car: driverCar });
			}
		});

		const finalDriverList = drivers.sort((a, b) => a.name.localeCompare(b.name));

		this.setState({ driversList: finalDriverList, driverKey: 0 });
	}

	optionButton = (item, type) => {
		return (
			<button
				type='button'
				key={item.id}
				className={
					type === 'client' ?
						this.state.clientKey === item.id ? 'btn-custom active' : 'btn-custom' :
						this.state.driverKey === item.id ? 'btn-custom driver active' : 'btn-custom driver'
				}
				onClick={() => type === 'client' ? this.filterDeliveries(item) : this.filterDeliveriesByDriver(item)}
				>
				{type === 'client' ? item.name : `${item.name} (${item.car})`}
			</button>
		);
	}

	filterDeliveries = (client) => {
		const data = this.state.data.deliveries;

		if (client.id === 0) { // all clients
			this.setState({ filteredData: data, clientKey: client.id }, () => this.getDriversList());
			return;
		}

		const filteredData = data.filter(
			delivery => delivery.carrierData.title === client.name
		);

		this.setState({ filteredData, clientKey: client.id }, () => this.getDriversList());
	}

	filterDeliveriesByDriver = (driver) => {
		const { data, clientsList, clientKey } = this.state;

		const dataToProcess = clientKey === 0 ? data.deliveries : data.deliveries.filter(
			delivery => delivery.carrierData.title === clientsList[clientKey].name
		);

		const filteredDataByDriver = dataToProcess.filter(
			delivery => delivery.carrierData.driver === driver.name && delivery.carrierData.vilcejs === driver.car
		);

		this.setState({ filteredData: filteredDataByDriver, driverKey: driver.id });
	}

	render() {
		const { data, filteredData, clientsList, driversList, loading, orderNr, planningOrderId, isSelected, isMassEditButtonsActive, showMassDeliveryEditModal } = this.state;
		const { billTitle } = this.props;
		const dataToShow = filteredData.length > 0 ? filteredData : data.deliveries;

		const title = planningOrderId ?
			`Plānošanas pasūtījuma #${planningOrderId} reģistrētās piegādes` :
			`Pasūtījuma #${orderNr} reģistrētās piegādes`;

		const renderContent = () => {
			if (loading) return <Loading />;
			if (typeof dataToShow !== 'undefined' && dataToShow.length === 0) return <NoData />;

			return (
				<>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '2px', alignItems: 'flex-end' }}>
						{this.props.canUpdateDeliveriesInBatch && (
							<div>
								<button
									type='button'
									className='btn btn-primary'
									onClick={this.activateMassEdit}
									style={{ display: 'block', width: '220px' }}>
									{isMassEditButtonsActive ? 'Deaktivizēt kopīgo labošanu' : 'Aktivizēt kopīgo labošanu'}
								</button>
							</div>
						)}
						<div style={{ display: 'flex', gap: '10px' }}>
							{isSelected.length > 0 && isMassEditButtonsActive && (
								<button
									type='button'
									className='btn btn-primary'
									onClick={this.handleOpenMassDeliveryEditModal}
									style={{ width: '220px' }}>
									Labot atzīmētās piegādes ({isSelected.length})
								</button>
							)}
							{isMassEditButtonsActive && (
								<button
									type='button'
									className='btn btn-primary'
									onClick={this.selectAllDeliveries}
									style={{ width: '220px' }}>
									{isSelected.length > 0 ? 'Noņemt atzīmes no piegādēm' : 'Atzīmēt visas piegādes'}
								</button>
							)}
						</div>
					</div>

					{clientsList.length > 2 && (<>
						<div className='clientBtnLayout'>
							{clientsList.map((client, ind) =>
								this.optionButton(client, 'client')
							)}
						</div>
					</>)}

					{driversList.length > 1 && (<>
						<div className='clientBtnLayout'>
							{driversList.map((driver, ind) =>
								this.optionButton(driver, 'driver')
							)}
						</div>
					</>)}

					<Deliveries
						data={dataToShow}
						exclude={[]}
						reload_data={this.setup}
						isSelected={this.state.isSelected}
						onCheckboxChange={this.handleCheckboxChange}
						selectAllDeliveries={this.selectAllDeliveries}
						isMassEditButtonsActive={this.state.isMassEditButtonsActive}
					/>
				</>
			);
		};

		return (
			<div>
				<CustomModal
					modalClass='fullWidthModal'
					title={billTitle ? `Rēķinā #${billTitle} iekļautās piegādes` : title}
					showModal={this.props.showModal}
					handleCloseModal={this.props.handleCloseModal}
					content={
						<div>
							{renderContent()}
						</div>
					}
				/>
				{showMassDeliveryEditModal && (
					<MassDeliveryEdit
						showModal={showMassDeliveryEditModal}
						handleCloseModal={this.handleCloseMassDeliveryEditModal}
						isSelected={this.state.isSelected}
						data={this.state.data.deliveries}
					/>
				)}
				<OrderEditModal
					showModal={this.state.showEditModal}
					handleCloseModal={this.handleCloseEditModal}
					closeModalWithoutReload={this.closeModalWithoutReload}
				/>
			</div>
		);
	}
}
//
const mapStateToProps = (state, ownProps) => ({
	canUpdateDeliveriesInBatch: state.auth.canUpdateDeliveriesInBatch,
})

export default connect(mapStateToProps)(VedumiModal);
