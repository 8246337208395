//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Tabs } from 'react-bootstrap';
import api from '@api';
import Loading from 'shared/loading';
import FilterText from '../../shared/filter/format';
import 'shared/tabs/tabs.css';
import ErrorOnLoading from 'shared/errorOnLoad';
import NoData from 'shared/noDataNotification';
import TabHandler from 'shared/tabs/tabSelect';
import oneTab from 'shared/tabs/oneTab';

let handler = new TabHandler();

const getDriverName = (ind, driverData) =>
	driverData.length >= ind ? driverData[ind].driverName : null;

class DriverTabs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			clientKey: 0,
			key: 0,
			loading: false,
			driverData: [],
			clientsList: [],
			filteredDrivers: [],
			error: false,
		};
	}

	setup(cb) {
		this.setState({ loading: true, error: false });

		api.deliveries.drivers().then(response => {
			let sortedDrivers= [];
			if (Array.isArray(response) && response.length > 0) {
				sortedDrivers = response.sort((a, b) => a.driverName.localeCompare(b.driverName));
			}

			this.setState({ loading: false, error: false, driverData: sortedDrivers }, () => cb());
		}).then(() => {
			this.getClientList();
		});
	}

	getClientList = () => {
		const { driverData } = this.state;

		let clients = [];
		driverData.forEach((driver, ind) => {
			if (!clients.some(client => client.name === driver.clientName)) {
				clients.push({ id: ind + 1, name: driver.clientName });
			}
		});

		const finalClientList = [
			{ id: 0, name: 'VISI PĀRVADĀTĀJI' },
			...clients.sort((a, b) => a.name.localeCompare(b.name))
		];

		this.setState({ clientsList: finalClientList });
	}

	setRightDriver = () => {
		let curTab = handler.getSettings('OrderTabs');
		if (curTab) {
			if (curTab > 0) {
				const driverName = getDriverName(curTab, this.state.driverData);
				const driverFromLocalStorage = handler.getDriver('OrderTabs');

				if (driverName === driverFromLocalStorage) {
					this.handleSelect(curTab);
				} else {
					handler.saveSettings('OrderTabs', 0, null);
				}
			} else {
				handler.saveSettings('OrderTabs', 0, null);
			}
		}
	};

	componentDidMount() {
		this.setup(this.setRightDriver);
	}

	handleSelect = key => {
		this.setState({ key });
		handler.saveSettings('OrderTabs', key, getDriverName(key, this.state.driverData));
	};

	clientOption = (client) => {
		return (
			<button
				type='button'
				key={client.id}
				className={this.state.clientKey === client.id ? 'btn-custom active' : 'btn-custom'}
				onClick={() => this.filterDrivers(client)}
				>
				{client.name}
			</button>
		);
	};

	filterDrivers = (client) => {
		const { driverData } = this.state;
		const filteredDrivers = client.id === 0 ? driverData : driverData.filter(driver => driver.clientName === client.name);

		this.setState({ filteredDrivers, clientKey: client.id, key: 0 });
	}

	render() {
		const { driverData, clientsList, filteredDrivers, error, loading } = this.state;
		const drivers = filteredDrivers.length > 0 ? filteredDrivers : driverData;

		const renderContent = () => {
			if (error) return <ErrorOnLoading />;
			if (loading) return <Loading />;
			if (drivers.length === 0) return <NoData />;

			return (
				<>
					<FilterText />
					<div className='clientBtnLayout'>
						{clientsList.map((client, ind) =>
							this.clientOption(client)
						)}
					</div>
					<br />
					<Tabs
						className='tabLayout'
						key={drivers.map(driver => driver.carrierId).join(',')}
						activeKey={this.state.key}
						onSelect={this.handleSelect}
						id='driver-tabs'>
						{drivers.map((tab, ind) =>
							oneTab(tab, ind, this.state.key)
						)}
					</Tabs>
				</>
			);
		}

		return (
			<div className='bodyWrapper'>
				{renderContent()}
			</div>
		);
	}
}

export default DriverTabs;
